
import { loadComponent, loadView } from './loadFn';
const routes = [
    {
        path: '/',
        name: 'briefIntroduction',
        component: loadView('BriefIntroduction/BriefIntroduction'),
        meta: {
            title: '郑投简介'
        },
    }, {
        path: '/AttractInvestment',
        name: 'AttractInvestment',
        component: loadView('AttractInvestment/AttractInvestment'),
        meta: {
            title: '园区招商'
        },
    },
    {
        path: '/ContactUs',
        name: 'ContactUs',
        component: loadView('ContactUs/ContactUs'),
        meta: {
            title: '联系我们'
        },
    },
    {
        path: '/Operate',
        name: 'Operate',
        component: loadView('Operate/Operate'),
        meta: {
            title: '科技运营'
        },
    },
    {
        path: '/RealimeInfo',
        name: 'RealimeInfo',
        component: loadView('RealimeInfo/RealimeInfo'),
        meta: {
            title: ' 园区资讯'
        },
    },
    {
        path: '/deailed',
        name: 'Deailed',
        component: loadView('DetailedInformation/DetailedInformation'),
        meta: {
            title: '公告'
        }
    },
    {
        path: '/ServiceZhengTou',
        name: 'ServiceZhengTou',
        component: loadView('ServiceZhengTou/ServiceZhengTou'),
        meta: {
            title: '郑投企服务'
        },
    }
    

]
export default routes;