<template>
  <div id="app">
    <el-container class="app-container">
      <el-header class="container topH" height = '90px'><Menu></Menu></el-header>
      <el-main class="container topH" > <router-view /></el-main>
      <div class="infoB"></div>
      <el-footer class="container"><Footer class="bottom-con"></Footer></el-footer>
    </el-container>
  </div>
</template>

<script scoped>
import Menu from "@/views/Menu/Menu.vue";
import Footer from '@/views/Footer/Footer.vue';
import regionCode from '@/utils/constant.js';
import { ref, onMounted } from "vue";
export default {
  name: "App",
  components: {
    Menu,
    Footer
  },
  setup() {
    onMounted(() => {
      let bottomH =
        document.getElementsByClassName("bottom-con")[0].clientHeight;
        // document.getElementsByClassName('infoB')[0].style.height = bottomH + 'px';
         document.getElementsByClassName('infoB')[0].style.height = 0 + 'px';
    });
  },
};
</script>

<style scoped>
.el-main{
  padding: 0;

}

.app-container{
  position: relative;
  min-height: 100vh;
 
}
.topH{
  width: 100vw;
}
</style>
