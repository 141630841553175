<template>
  <div class="footer-container" :class="{ active: routerDRef }">
    <div class="footer-container-n footer-container-mead">
      <div class="logos item" style="margin-top:5px">
        <img src="../../assets/images/logo.png" />
      </div>
      <div class="zhongCon item">
        <div class="itemZ">园区地址：郑州市二七区公安路和月光路的交叉口附近</div>
        <div class="itemZ">科技运营：郑州智慧园区科技运营有限公司</div>
      </div>
      <div class="rightCon">
        <div class="itemZ">招商电话：0371-68187777</div>
        <div class="itemZ">邮箱：zztjzx@163.com</div>
      </div>
    </div>

    <!-- <div class="footer-container-n footer-container-mead">
      <div class="logos">
        <img src="../../assets/images/logo.png" />
      </div>

      <div class="footer-info">
        <div class="footer-info-left">
          <div>园区地址：郑州市二七区公安路和月光路的交叉口附近</div>
          <div>招商电话：0371-68187777</div>
          <div>邮箱：zztjzx@163.com</div>
          <div>科技运营：郑州智慧园区科技运营有限公司</div>
        </div>
        <div class="footer-info-right">
          <div>
            <img src="../../assets/images/erweima.png" />
          </div>
          <div class="title">扫码关注我们</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import getRoter from "./competition/getRouter";
export default {
  setup() {
    return {
      ...getRoter(),
    };
  },
};
</script>

<style scoped>
.footer-container {
  height: 80px;
  width: 100%;
  margin: 0 auto;
  position: absolute;

  bottom: 0;
}
.item{
    margin-right: 100px;
}
.footer-container-n {
  height: 80px;
  max-width: 1420px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  font-size: 14px;
  color: #000000;
}
.footer-container.active {
  background: rgba(242, 245, 248, 1);
}
.itemZ{
  margin-top: 5px;
}
.footer-info-right {
  color: #999999;
  margin-left: 196px;
  text-align: center;
}
@media screen and (max-width: 1420px) {
  .item{
    margin-right: 0px;
  }
  .footer-container-n {
    width: 100%;
    justify-content: space-around;
  }
}
.footer-container .logos {
  width: 285px;
  position: relative;
}
.footer-container .logos img {
  width: 100%;
}
.footer-info {
  display: flex;
  align-items: center;
}
.footer-info-right img {
  width: 100px;
  height: 100px;
}

.footer-info-left div {
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 5px;
  font-family: PingFang SC;
}
</style>
