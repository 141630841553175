<template>
  <div class="menu-container">
    <div class="menu-container-n">
      <div class="logo">
        <img src="../../assets/images/logo.png" />
      </div>
      <div class="handMenu handMenu-wh">
        <el-menu
          :default-active="activeIndexRef"
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          @select="handleSelect"
          text-color="#555"
          active-text-color="#000000"
        >
          <el-menu-item index="/">园区简介 </el-menu-item>
          <el-menu-item index="/RealimeInfo">园区资讯</el-menu-item>
          <el-menu-item index="/ServiceZhengTou">园区企服</el-menu-item>
          <el-menu-item index="/AttractInvestment">园区招商</el-menu-item>
          <el-menu-item index="/Operate">科技运营</el-menu-item>
          <el-menu-item index="/ContactUs">联系我们</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script scoped>
import handleSelect from "./competition/navigationMenu.js";
export default {
  setup() {
    return {
      ...handleSelect(),
    };
  },
};
</script>

<style scoped>
.handMenu {
  /* width: 1920px; */
  /* max-width: 870px !important; */
  
  margin: 0 ;
  box-sizing: border-box;
  position: relative;
}
.menu-container {
  position: relative;
  /* width: 100%; */
  /* min-width: 1920px; */
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);

  width: 100%;
}
@media screen and (max-width:1420px) {
  .logo {
    padding-left: 40px !important;
  }
}

.menu-container-n {
  display: flex;
  max-width: 1420px;
  align-items: center;
  justify-content: space-between !important;
  margin: 0 auto;
}
.menu-container .el-menu-item {
  font-size: 20px;
  border-bottom: none;
  padding: 0;
  margin-left: 88px;
}
@media screen and (max-width: 1453px){
  .menu-container .el-menu-item{
  margin-right: 50px;
  margin-left: 0;
  }
}
.menu-container .el-menu-item:hover {
  background-color: #fff !important;
}
.menu-container .el-menu-item.is-active {
  font-weight: bold;
  background-color: #fff !important;
  border-bottom: none;
  position: relative;
}
.menu-container .el-menu-item.is-active::after {
  content: "";
  display: block;
  position: absolute;
  width: 40px;
  height: 6px;
  background: rgba(0, 0, 0, 1);
  bottom: 20px;
  left: calc(50% - 20px);
}
.el-menu {
  /* min-width:1420px;  */
  height: 90px;
  box-sizing: border-box;
  padding-left: 0px;
  width: 100%;
  box-sizing: border-box;
}
.logo {
  width: 285px;
  height: 50px;
  z-index: 100;
  flex: 0 0 auto;
}
.logo img {
  width: 100%;
  height: 100%;
}
</style>
