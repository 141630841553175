import {ref,onMounted,nextTick} from 'vue'
import { router } from '@/route/index.js'
import mitt from '../../../utils/mitt'
export default function menu(){
    const activeIndexRef = ref('/');
    onMounted(() => {
        setTimeout(() => {
            nextTick(() =>{
                activeIndexRef.value = router.currentRoute.value.path;
                mitt.emit('setRouter', activeIndexRef.value);
            })
        },200)
        
    })
    const handleSelect = (key, keyPath) => {
        router.push(`${keyPath[0]}`);
        mitt.emit('setRouter', keyPath[0]);
    }
    return {
        handleSelect,
        activeIndexRef
    }
}