import {ref} from 'vue'
import mitt from '../../../utils/mitt';
const isBgList = ['/RealimeInfo','/ServiceZhengTou','/AttractInvestment','/ContactUs','/deailed'];
export default function getRouter(){
    let routerDRef = ref(false);
    mitt.on('setRouter',(e) => {
        routerDRef.value = e;
        if(isBgList.includes(e) ){
            routerDRef.value = true;
        }else{
            routerDRef.value = false;
        }
        if(e.search(/\/deailed\//) != -1)                                                                                          {
            routerDRef.value = true;
        }
    });
    return {
        routerDRef
    }
}