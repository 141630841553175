let regionCode = '';
import {queryWebSiteBindingCodeUrl,fly} from '../requestUrl';
if(!regionCode){
    (async function () {
        let res =   await fly.get(`${queryWebSiteBindingCodeUrl}?websiteName=zhengtou.techops.cn`);
        console.log(res)    ;
        regionCode = res.data.data.regionCode;
        localStorage.setItem('regionCode',regionCode);

    })()
}


export {
    regionCode
}