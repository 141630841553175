import fly from "./flyio/flyConfig";

//网站提交接口
const sitesSubmitUrl = '/lease/leadsManage/sitesSubmit';
//分配用户接口
const allotSitesUserUrl = '/lease/leadsManage/allotSitesUser';
//获取短信验证码接口https://api.cosmos-property.com
const getSmsCodeUl = '/lease/leadsManage/getSmsCode';
//查询公告-----
const queryParkSitesUrl = '/social/notice/queryPCLogin';
//查询园区网站新闻接口
const queryParkNewsUrl= '/social/notice/queryParkNews';
//办事指南展示数据接口
const filedownloadUrl = '/social/banshizhinan/showAll';
//资料下载中展示数据接口
const showAllUrl = '/social/filedownload/showAll';
//查询公告、新闻(后台和官网用同一个)
const queryWebsiteContentAll = '/lease/website/queryWebsiteContent';
//根据网站名称查询园区code (官网用)
const queryWebSiteBindingCodeUrl = '/lease/website/queryWebSiteBindingCode';
export{
    sitesSubmitUrl,
    allotSitesUserUrl,
    getSmsCodeUl,
    fly,
    queryParkSitesUrl,
    queryParkNewsUrl,
    showAllUrl,
    queryWebsiteContentAll,
    filedownloadUrl,
    queryWebSiteBindingCodeUrl
}
