function loadComponent(componentName) {
    return () =>
        import(
            /* webpackChunkName:"view-[request]" */ `@/components/${componentName}.vue`
            );
}

function loadView(componentName) {
    return () =>
        import(
            /* webpackChunkName:"view-[request]" */ `@/views/${componentName}.vue`
            );
}

export {loadComponent, loadView}
